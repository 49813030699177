import React, { useState, useEffect, useRef } from "react";
// import { NavLink } from 'react-router-dom';
import PageHelmet from "../component/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import TeamButton from "../elements/team/TeamButton";
import StaffButton from "../elements/team/StaffButton";
import Biographies from "./team/Biographies"
import axios from 'axios';
import { useQueryStringKey } from 'use-route-as-state'



function Team(props) {
    const [activeTab, setActiveTab] = useState("providers");
    const [activeButton, setActiveButton] = useState('')
    const [providers, setProviders] = useState([])
    const [staff, setStaff] = useState([]);
    const [url, setURL] = useQueryStringKey('provider', 'none selected')
    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' })

    useEffect(() => {
        const fetchProviders = async () => {
            try {
                const { data: response } = await axios.get('https://rpsych.sfo3.digitaloceanspaces.com/ProviderList.json');
                // Create a new map to group providers by ID
                const providersMap = new Map();
                response.forEach((provider) => {
                    // Ignore virtual offices
                    if (provider.OfficeName === 'Virtual') return;

                    // Check if the provider is already in the map
                    if (!providersMap.has(provider.ID_Provider)) {
                        // If not, add them with an array containing their first office
                        providersMap.set(provider.ID_Provider, {
                            ...provider,
                            Offices: [provider.OfficeName]
                        });
                    } else {
                        // If so, add the new office to their Offices array
                        const existingProvider = providersMap.get(provider.ID_Provider);
                        existingProvider.Offices.push(provider.OfficeName);
                    }
                });

                // Convert the map values to an array and sort it
                const sortedProviders = Array.from(providersMap.values())
                    .sort(alphabetically(true));

                // Update the providers state with the sorted providers
                setProviders(sortedProviders);
            } catch (error) {
                console.error(error.message);
            }
        }
        const fetchStaff = async () => {
            try {
                const { data: response } = await axios.get('https://rpsych.sfo3.digitaloceanspaces.com/StaffList.json');
                // Define the desired order
                const customOrder = ["Dan Friday, CEO", "Heather Morin, COO", "Andrew Isabella, DoN", "Garrett Smith, DoMR"];

                // Sort the staff according to the custom order
                const sortedStaff = response.sort((a, b) => {
                    const orderA = customOrder.indexOf(a.DisplayName);
                    const orderB = customOrder.indexOf(b.DisplayName);
                    return orderA - orderB;
                });
                setStaff(sortedStaff);
            } catch (error) {
                console.error("Error fetching staff:", error.message);
            }
        };

        fetchProviders();
        fetchStaff();
    }, []);

    function alphabetically(ascending) {
        return function (a, b) {
            // equal items sort equally
            if (a.ListOrder === b.ListOrder) {
                return 0;
            }
            // nulls sort after anything else
            else if (a.ListOrder === null) {
                return 1;
            }
            else if (b.ListOrder === null) {
                return -1;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (ascending) {
                return a.ListOrder < b.ListOrder ? -1 : 1;
            }
            // if descending, highest sorts first
            else {
                return a.ListOrder < b.ListOrder ? 1 : -1;
            }
        };
    }
    return (
        <React.Fragment>
            <PageHelmet pageTitle='Team Biographies' />
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Breadcrump Area */}
            <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--20" data-black-overlay="5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title color-1">{activeTab === "providers" ? "Provider Biographies" : "Admin & Outreach"}</h2>
                                <p>{activeTab === "providers" ? "Select a provider to reveal their profile." : "Staff Contact Information"}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Resource Area */}
            <div className="service-area ptb--20 bg_color--3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--30">
                                <div ref={myRef}></div>
                                <div
                                    className="d-flex justify-content-between my-4"
                                    style={{ maxWidth: "700px", margin: "0 auto", gap: "20px" }}
                                >
                                    <button className="rn-button-style--2 btn-solid"
                                        size="lg"
                                        onClick={() => setActiveTab("providers")}
                                        style={{
                                            flex: 1,
                                            textAlign: "center",
                                            backgroundColor: activeTab === "providers" ? "#294d8f" : "#6c757d",
                                            borderColor: activeTab === "providers" ? "#294d8f" : "#6c757d",
                                        }}
                                    >
                                        Providers
                                    </button>
                                    <button className="rn-button-style--2 btn-solid"
                                        size="lg"
                                        onClick={() => setActiveTab("staff")}
                                        style={{
                                            flex: 1,
                                            textAlign: "center",
                                            backgroundColor: activeTab === "staff" ? "#294d8f" : "#6c757d",
                                            borderColor: activeTab === "staff" ? "#294d8f" : "#6c757d",
                                        }}
                                    >
                                        Staff Members
                                    </button>
                                </div>
                                <div style={{
                                    backgroundColor: "#fff", // Light background
                                    borderRadius: "8px", // Rounded corners
                                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Shadow for depth
                                    padding: "20px", // Space inside the div
                                    marginRight: "-10px",
                                    marginLeft: "-10px",
                                }}>
                                    {activeTab === "providers" ? (
                                        <div>
                                            <div>
                                                <Biographies
                                                    currentProvider={providers.filter(function (provider) {
                                                        return provider.DisplayName === url
                                                    })}
                                                />
                                                <br />
                                            </div>
                                            <div className="row" style={{ justifyContent: 'center' }}>
                                                {providers.map((item, index) => (
                                                    <TeamButton
                                                        key={index}
                                                        id={index}
                                                        DisplayName={item.DisplayName}
                                                        FirstName={item.FirstName}
                                                        LastName={item.LastName}
                                                        PictureLink={item.PictureLink}
                                                        CurrentlyAcceptingPatients={item.CurrentlyAcceptingPatients}
                                                        setActiveButton={setActiveButton}
                                                        executeScroll={executeScroll}
                                                        setURL={setURL}
                                                        Offices={item.Offices}
                                                        url={url}
                                                        active={activeButton === index ? true : false}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    ) : (
                                        //Staff tab
                                        <div className="row" style={{ justifyContent: 'center', marginTop: '20px' }}>
                                            {staff.map((item) => (
                                                <StaffButton
                                                    key={item.ID_Staff}
                                                    DisplayName={item.DisplayName}
                                                    Title={item.Title}
                                                    PictureLink={item.PictureLink}
                                                    Email={item.Email}
                                                    Phone={item.Phone}
                                                />
                                            ))}
                                        </div>
                                    )}<br /><br />
                                </div><br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Resource Area */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />


        </React.Fragment>
    );
};

export default Team;
