import React, { useState } from "react";
import Card from 'react-bootstrap/Card';

const TeamButton = (props) => {
   const [hover, setHover] = useState(false);

   const clickHandler = () => {
      props.setURL(props.DisplayName);
      props.setActiveButton(props.id);
      props.executeScroll();
   };

   const cardStyle = (props) => {
      const baseStyle = {
         width: '18rem',
         cursor: 'pointer',
         margin: '10px',
         borderRadius: '10px',
         border: 'none',
         marginRight: '10px',
         background: 'linear-gradient(to bottom, #3d3e40 85%, #8b8b8b 100%)',
         transition: 'transform 0.3s ease, box-shadow 0.3s ease',
         textShadow: '0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)',
      };

      if (props.active) {
         return {
            ...baseStyle,
            border: '6px solid green',
         };
      }

      return baseStyle;
   };

   const renderOffices = () => {
      return props.Offices && props.Offices.map((office, index) => (
         <span key={index}>
            {office}{index < props.Offices.length - 1 ? ', ' : ''}
         </span>
      ));
   };

   const getResizedImageURL = (url, width, height) => {
      return `${url}?width=${width}&height=${height}&quality=80&fit=cover`;
   };

   return (
      <Card
         style={cardStyle(props)}
         onClick={clickHandler}
         onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'translate(-5px, -5px)';
            e.currentTarget.style.boxShadow = 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 8px 15px 12px';
            setHover(true); // Added the hover state toggle here
         }}
         onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'translate(0, 0)';
            e.currentTarget.style.boxShadow = 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px';
            setHover(false); // Reset the hover state
         }}
      >
         <img
            src={getResizedImageURL(props.PictureLink, 200, 360)}
            alt={props.DisplayName}
            style={{
               width: '100%',
               height: '360px',
               objectFit: 'cover',
               objectPosition: 'center',
               borderTopLeftRadius: '10px',
               borderTopRightRadius: '10px',
            }}
         />
         {hover && (
            <div style={{
               position: 'absolute',
               top: 0,
               left: 0,
               right: 0,
               bottom: 0,
               backgroundColor: 'rgba(0, 0, 0, 0.5)',
               color: '#eeeeee',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               fontSize: '1.5rem',
               borderRadius: '10px',
            }}>
               Read Biography
            </div>
         )}
         <div style={{ width: '100%', textAlign: 'center', overflow: 'visible' }}>
            <div
               style={{
                  backgroundImage: `url('/assets/images/bg/bg-image-51.png')`,
                  backgroundSize: '85%',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  height: '50px',
                  marginTop: '-15px',
                  marginBottom: '-15px',
               }}
            ></div>
            <Card.Title
               style={{
                  marginBottom: '-5px',
                  color: '#eeeeee',
                  fontSize: '1.5rem',
               }}
            >
               {props.DisplayName}
            </Card.Title>
            <Card.Text
               style={{
                  color: '#eeeeee',
                  fontSize: '1rem',
               }}
            >
               {renderOffices()}
            </Card.Text>
         </div>
      </Card>
   );
};

export default TeamButton;
