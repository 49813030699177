import React from "react";
import Card from 'react-bootstrap/Card';

const StaffButton = (props) => {
    const isKBradshaw = props.Email === "kbradshaw@rpsych.com";

    return (
        <Card
            style={{
                width: '18rem',
                margin: '10px',
                borderRadius: '10px',
                border: 'none',
                background: 'linear-gradient(to bottom, #3d3e40 85%, #8b8b8b 100%)',
                color: 'white',
                textShadow: '0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)',
                fontSize: isKBradshaw ? '12px' : '1rem',
            }}
        >
            {/* Staff Photo */}
            <Card.Img
                variant="top"
                src={props.PictureLink}
                alt={props.DisplayName}
                style={{
                    height: '360px',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                }}
            />

            {/* Decorative Background Below Photo */}
            <div
                style={{
                    backgroundImage: `url('/assets/images/bg/bg-image-51.png')`,
                    backgroundSize: '85%',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    height: '50px',
                    marginTop: '-15px',
                    marginBottom: '-35px',
                }}
            ></div>

            {/* Staff Details */}
            <Card.Body>
                <Card.Title
                    style={{
                        marginBottom: '-5px',
                        color: '#eeeeee',
                        fontSize: isKBradshaw ? '1.365rem' : '1.5rem',
                        textAlign: 'center',
                    }}
                >
                    {props.DisplayName}
                </Card.Title>
                <Card.Text
                    style={{
                        color: '#eeeeee',
                        fontSize: isKBradshaw ? '0.96rem' : '1rem',
                        textAlign: 'center',
                        marginTop: '0px',
                    }}
                >
                    <span style={{ marginBottom: '10px', fontSize: "14px" }}>{props.Title}</span>
                    <br />
                    <strong>Email: </strong> <a href={`mailto:${props.Email}`} style={{ color: 'white', textDecoration: 'none' }}>
                        {props.Email}
                    </a>
                    <br />
                    <strong>Phone: </strong>
                    <a href={`tel:${props.Phone}`} style={{ color: 'white', textDecoration: 'none' }}>
                        {props.Phone}
                    </a>
                    {isKBradshaw && <span style={{ marginLeft: '5px' }}>Ext. 213</span>}
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

export default StaffButton;
